// src/App.js
import React, { useState, useEffect } from "react";
import P5Component from "./P5Component";
import "./css/OverlayMask.css";

import oh1 from "./images/oceano/oceano1.jpg";
import oh2 from "./images/oceano/oceano2.jpg";
import oh3 from "./images/oceano/oceano3.jpg";
import oh4 from "./images/oceano/oceano4.jpg";
import oh5 from "./images/oceano/oceano5.jpg";

import fm1 from "./images/farm/farm1.jpg";
import fm2 from "./images/farm/farm2.jpg";
import fm3 from "./images/farm/farm3.jpg";
import fm4 from "./images/farm/farm4.jpg";
import fm5 from "./images/farm/farm5.jpg";

import la2 from "./images/la/la1.jpg";
import la5 from "./images/la/la2.jpg";
import la7 from "./images/la/la3.jpg";
import la8 from "./images/la/la4.jpg";
import la9 from "./images/la/la5.jpg";
import la10 from "./images/la/la9.jpg";
import la11 from "./images/la/la10.jpg";


import nyc2 from "./images/nyc/nyc2.jpg";
import nyc3 from "./images/nyc/nyc3.jpg";
import nyc4 from "./images/nyc/nyc4.jpg";
import nyc5 from "./images/nyc/nyc5.jpg";
import nyc6 from "./images/nyc/nyc6.jpg";
import nyc7 from "./images/nyc/nyc7.jpg";
import nyc8 from "./images/nyc/nyc8.jpg";

import uc1 from "./images/construction/construction1.jpg";
import uc2 from "./images/construction/construction2.jpg";
import uc3 from "./images/construction/construction3.jpg";
import uc4 from "./images/construction/construction4.jpg";
import uc5 from "./images/construction/construction5.jpg";
import uc6 from "./images/construction/construction6.jpg";
import uc7 from "./images/construction/construction7.jpg";

import ss1 from "./images/sea/sea1.jpg";
import ss3 from "./images/sea/sea3.jpg";
import ss4 from "./images/sea/sea4.jpg";
import ss5 from "./images/sea/sea5.jpg";
import ss6 from "./images/sea/sea6.jpg";
import ss7 from "./images/sea/sea7.jpg";
import ss8 from "./images/sea/sea8.jpg";

import gif1 from "./images/gifs/muse.gif"
import gif2 from "./images/gifs/bertmoticon.gif"




function App() {
  const [key, setKey] = useState(0); // State to force re-render

  useEffect(() => {
    function handleResize() {
      // Update state to force re-render on resize or orientation change
      setKey((prevKey) => prevKey + 1);
    }

    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, []);

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  const linkStyle = {
    textDecoration: "none",
    transition: "color 0.3s" // Smooth color transition over 0.3 seconds
  };

  const handleExternalLinkClick = (url) => {
    window.open(url, "_blank"); // Opens the URL in a new tab
  };

  const handleEmailLinkClick = (event) => {
    event.preventDefault(); // Prevent default behavior
    if (window.location.href !== "mailto:st.stoikos@gmail.com") {
      window.location.href = "mailto:st.stoikos@gmail.com"; // Navigate to email client
    }
  };

  const wideListStyle = {
    width: "80%", // Adjust the width as needed
    textAlign: "right", // Aligns the content to the right
    marginLeft: "auto", // Aligns the entire list to the right
    paddingRight: "0", // Removes right padding
    marginTop: "0", // Removes top margin
  };

  const firstWordStyle = {
    color: "white", // Style for the first word
  };

  const restOfText = {
    color: "rgb(255, 247, 198)", // Style for the rest of the text
  };

  const listItemStyle = {
    textAlign: "right", // Aligns text within each list item to the right
  };


  const blueColor = { color: "rgb(81, 133, 188)" };
  const paragraphStyleFirst = { marginBottom: "250px", textAlign: "right" };
  const paragraphStyle = { marginBottom: "320px", textAlign: "right" }; // Adding margin between paragraphs
  const paragraphStylePhotos = { marginTop: "20px", textAlign: "right" };

  return (
    <main>
      <div className="outer-overlay">
        <div className="inner-overlay"></div>
      </div>
      <div className="top-overlay">
        <ul>
          <h1>Stefanos Stoikos</h1>
          <h3>software engineer & photography</h3>
          <br></br>
          <li>
            <a
              href="#about"
              style={linkStyle}
              onClick={() => scrollToSection("section1")}
              onMouseEnter={(e) => (e.target.style.color = "rgb(81, 133, 188)")} // Changes color on hover
              onMouseLeave={(e) => (e.target.style.color = "whitesmoke")}
            >
              about
            </a>
          </li>
          <li>
            <a
              href="#work"
              style={linkStyle}
              onClick={() => scrollToSection("section2")}
              onMouseEnter={(e) => (e.target.style.color = "rgb(81, 133, 188)")} // Changes color on hover
              onMouseLeave={(e) => (e.target.style.color = "whitesmoke")}
            >
              work
            </a>
          </li>
          <li>
            <a
              href="#photography"
              style={linkStyle}
              onClick={() => scrollToSection("section3")}
              onMouseEnter={(e) => (e.target.style.color = "rgb(81, 133, 188)")} // Changes color on hover
              onMouseLeave={(e) => (e.target.style.color = "whitesmoke")}
            >
              photography
            </a>
          </li>
          <li>
            <a
              href="#contact"
              style={linkStyle}
              onClick={() => scrollToSection("section4")}
              onMouseEnter={(e) => (e.target.style.color = "rgb(81, 133, 188)")} // Changes color on hover
              onMouseLeave={(e) => (e.target.style.color = "whitesmoke")}
            >
              contact
            </a>
          </li>
        </ul>
        <div className="container">
          <div className="scrollable-content">
            <section id="section0" style={{ textAlign: "right" }}>
              <p style={paragraphStyleFirst}></p>
            </section>
            <section
              id="section1"
              style={{ textAlign: "right", width: "50%", marginLeft: "auto", paddingBottom: "100px" }}
            >
              <h2 style={blueColor}>about</h2>
              <p style={paragraphStyle}>
                I'm a data engineer based in Los Angeles with extensive experience in backend development, 
                data engineering, and AI research. I studied Computer Science at Pomona College where I conducted 
                research on multilingual LLMs, sentiment analysis, recommender systems, and networks. Outside of work,
                 I enjoy capturing the world through film and drones.
              </p>
            </section>

            <section id="section2" style={{ textAlign: "right" }}>
              <h2 style={blueColor}>work</h2>
              <p style={paragraphStyle}>
                <ul style={wideListStyle}>
                  <li style={listItemStyle}>
                    <span style={firstWordStyle}>
                      NORDSTROM, los angeles, CA
                    </span>
                  </li>
                  <li style={listItemStyle}>
                    <span style={restOfText}>
                      data engineer ii, 2022-current
                    </span>
                  </li><br></br>
                    <span style={{ maxWidth: '60%', display: 'inline-block', whiteSpace: 'normal' }}>
                      Designed and built distributed data processing pipelines to deliver real-time analytics for both day-to-day operations and long-term strategic decisions. Leveraged <span style={restOfText}>Kafka, Flink, Java, Spark, Kubernetes,</span> and <span style={restOfText}>Airflow</span> in conjunction with <span style={restOfText}>Teradata, GCP,</span> and <span style={restOfText}>AWS</span> to enhance Nordstrom’s analytical platform. Collaborated closely with business, data science, and engineering teams to understand key business needs and develop scalable, efficient data solutions that drive analytics and decision-making.                    </span><br></br><br></br>
                  <li style={listItemStyle}>
                    <hr style={{ width: '60%',float:'right', border: '1px solid white' }} />
                  </li><br></br><br></br>
                  <li style={listItemStyle}>
                    <span style={firstWordStyle}>
                      POMONA COLLEGE, claremont, CA
                    </span>
                  </li>
                  <li style={listItemStyle}>
                    <span style={restOfText}>
                      computer science research assistant, 2022
                    </span>
                  </li><br></br>
                    <span style={{ maxWidth: '60%', display: 'inline-block', whiteSpace: 'normal' }}>
                      Published the paper 'Cross-Language Music Recommendation Exploration' at ACM ICMR 2023, receiving the SIGMM Award. Built a dataset of 200K artists across nine languages using <span style={restOfText}>SQLite</span> and <span style={restOfText}>igraph</span>, leveraging a grid-search approach. Developed a cross-language recommendation engine combining <span style={restOfText}>matrix factorization</span> and <span style={restOfText}>BFS-based search</span>, achieving 2.5× higher accuracy than a random baseline in top-k genre-to-genre similarity tests.  </span><br></br><br></br>
                  <li style={listItemStyle}>
                    <hr style={{ width: '60%',float:'right', border: '1px solid white' }} />
                  </li><br></br><br></br>
                  <li style={listItemStyle}>
                    <span style={firstWordStyle}>
                      FERRYHOPPER, athens, greece
                    </span>
                  </li>
                  <li style={listItemStyle}>
                    <span style={restOfText}>
                      data engineer, 2021
                    </span>
                  </li><br></br>
                    <span style={{ maxWidth: '60%', display: 'inline-block', whiteSpace: 'normal' }}>
                    Developed an ETL framework and GraphDB for commercial ferry data from 45+ companies using <span style={restOfText}>Python, AWS,</span> and <span style={restOfText}>Neo4j</span>. Designed a multi-threaded load process to handle 200K daily data points from 250 ports across Europe, Asia, and Africa via <span style={restOfText}>AWS Lambda</span>. Built a Neo4j-based GraphDB, enabling 2–3× faster queries for direct and indirect trip searches. Provided REST endpoints for the data science team to analyze recurring trips, ticket pricing, and availability.                    </span><br></br><br></br>
                  <li style={listItemStyle}>
                    <hr style={{ width: '60%',float:'right', border: '1px solid white' }} />
                  </li><br></br><br></br>
                  <li style={listItemStyle}>
                    <span style={firstWordStyle}>
                      CLAREMONT MCKENNA COLLEGE, claremont, CA
                    </span>
                  </li>
                  <li style={listItemStyle}>
                    <span style={restOfText}>
                      mathematics research assistant, 2020
                    </span>
                  </li><br></br>
                    <span style={{ maxWidth: '60%', display: 'inline-block', whiteSpace: 'normal' }}>
                    Published the paper 'Multilingual Emoticon Prediction of Tweets about COVID-19' at ACL COLING 2020 and released a corresponding <span style={restOfText}>PyPI</span> library. Trained Google’s BERT on 54.2 million geolocated tweets to predict emoticon reactions across 102 languages. Applied the model to analyze Twitter users’ emotional responses to COVID-19 news. </span><br></br><br></br>
                  <li style={listItemStyle}>
                  </li><br></br>
                </ul>
                <h2 style={blueColor}>publications</h2>
                <ul style={wideListStyle}>
                  <li style={listItemStyle}>
                    <span style={firstWordStyle}>
                      <a
                        href="https://dl.acm.org/doi/abs/10.1145/3591106.3592274"
                        style={linkStyle}
                        onClick={() =>
                          handleExternalLinkClick(
                            "https://dl.acm.org/doi/abs/10.1145/3591106.3592274"
                          )
                        }
                        onMouseEnter={(e) =>
                          (e.target.style.color = "rgb(81, 133, 188)")
                        } // Changes color on hover
                        onMouseLeave={(e) =>
                          (e.target.style.color = "whitesmoke")
                        }
                        target="_blank" // Opens the link in a new tab
                        rel="noopener noreferrer" // Recommended for security reasons
                      >
                        Cross-Language Music Recommendation Exploration
                      </a>
                    </span>
                  </li>
                  <br></br>
                  <li style={listItemStyle}>
                    <span style={restOfText}>
                      abstract:
                    </span>
                  </li>
                  <li style={listItemStyle}>
                    <span style={{ maxWidth: '60%', display: 'inline-block', whiteSpace: 'normal' }}>
                      Recommendation systems are essential for music platforms to drive exploration and discovery for users. Little work has been done in exploring cross-language music recommendation systems, which represent another avenue for music exploration. In this paper, we collected and created a database of over 200,000 artists, which includes subsets of artists that sing in eight different languages other than English. Our goal was to recommend artists in those eight other language subsets for a given English-speaking artist. Using Spotify’s API-related artists feature, we implemented two approaches: a matrix factorization model using alternating least squares and a breadth-first search system. Both systems perform significantly better than a random baseline based on the accuracy of the base artist’s genre, with the breadth-first search model outperforming the matrix factorization technique. We conclude with suggestions for improving the performance and reach of cross-language music recommendation systems.                    </span>
                  </li>
                  <br></br>
                  <li style={listItemStyle}>
                    <span style={restOfText}>
                      acm icmr - thessaloniki, greece, jun 12-15, 2023
                    </span>
                  </li>
                  <img src={gif1} alt="" style={{ maxWidth: "600px", width: "100%", height: "auto" }} />
                  <br></br>
                  <li style={listItemStyle}>
                    <hr style={{ width: '60%',float:'right', border: '1px solid white' }} />
                  </li>
                  <br></br><br></br>
                  <li style={listItemStyle}>
                    <span style={firstWordStyle}>
                      <a
                        href="https://aclanthology.org/2020.peoples-1.11/"
                        style={linkStyle}
                        onClick={() =>
                          handleExternalLinkClick(
                            "https://aclanthology.org/2020.peoples-1.11/"
                          )
                        }
                        onMouseEnter={(e) =>
                          (e.target.style.color = "rgb(81, 133, 188)")
                        } // Changes color on hover
                        onMouseLeave={(e) =>
                          (e.target.style.color = "whitesmoke")
                        }
                        target="_blank" // Opens the link in a new tab
                        rel="noopener noreferrer" // Recommended for security reasons
                      >
                        {" "}
                        Multilingual Emoticon Prediction of Tweets About COVID-19{" "}
                      </a>
                    </span>
                  </li>
                  <br></br>
                  <li style={listItemStyle}>
                    <span style={restOfText}>
                      abstract:
                    </span>
                  </li>
                  <li style={listItemStyle}>
                  <span style={{ maxWidth: '60%', display: 'inline-block', whiteSpace: 'normal' }}>
                    Emojis are a widely used tool for encoding emotional content in informal messages such as tweets, and predicting which emoji corresponds to a piece of text can be used as a proxy for measuring the emotional content in the text. This paper presents the first model for predicting emojis in highly multilingual text. Our bertmoticon model is a fine-tuned version of the bert model,and it can predict emojis for text written in 102 different languages. We trained our bertmoticon model on 54.2 million geolocated tweets sent in the first 6 months of 2020, and we apply the model to a case study analyzing the emotional reaction of twitter users to news about the coronavirus. Example findings include a spike in sadness when the world health organization (who) declared that coronavirus was a global pandemic, and a spike in anger and disgust when the number of covid-19 related deaths in the united states surpassed one hundred thousand. We provide an easy-to-use and open source python library for predicting emojis with bertmoticon so that the model can easily be applied to other data mining tasks.  </span>
                  </li>
                  <br></br>
                  <li style={listItemStyle}>
                    <span style={restOfText}>
                      acl coling - barcelona, spain, dec 8-13, 2020
                    </span>
                  </li>
                  <img src={gif2} alt="" style={{ maxWidth: "600px", width: "100%", height: "auto" }} />
                </ul>
              </p>
            </section>
            <section id="section3" style={{ textAlign: "right" }}>
              <h2 style={blueColor}>photography</h2>
              <p style={paragraphStylePhotos}>
                oceano highway
              </p>
              <div class="scroll-container">
                <div class="image-grid">
                  <div class="grid-item">
                    <img src={oh1} alt=""></img>
                  </div>
                  <div class="grid-item">
                    <img src={oh2} alt=""></img>
                  </div>
                  <div class="grid-item">
                    <img src={oh3} alt=""></img>
                  </div>
                  <div class="grid-item">
                    <img src={oh4} alt=""></img>
                  </div>
                  <div class="grid-item">
                    <img src={oh5} alt=""></img>
                  </div>
                </div>
              </div>
              <p style={paragraphStylePhotos}>
                the farm
              </p>
              <div class="scroll-container">
                <div class="image-grid">
                  <div class="grid-item">
                    <img src={fm1} alt=""></img>
                  </div>
                  <div class="grid-item">
                    <img src={fm2} alt=""></img>
                  </div>
                  <div class="grid-item">
                    <img src={fm3} alt=""></img>
                  </div>
                  <div class="grid-item">
                    <img src={fm4} alt=""></img>
                  </div>
                  <div class="grid-item">
                    <img src={fm5} alt=""></img>
                  </div>
                </div>
              </div>
              <p style={paragraphStylePhotos}>
                under construction
              </p>
              <div class="scroll-container">
                <div class="image-grid">
                  <div class="grid-item">
                    <img src={uc1} alt=""></img>
                  </div>
                  <div class="grid-item">
                    <img src={uc2} alt=""></img>
                  </div>
                  <div class="grid-item">
                    <img src={uc3} alt=""></img>
                  </div>
                  <div class="grid-item">
                    <img src={uc4} alt=""></img>
                  </div>
                  <div class="grid-item">
                    <img src={uc5} alt=""></img>
                  </div>
                  <div class="grid-item">
                    <img src={uc6} alt=""></img>
                  </div>
                  <div class="grid-item">
                    <img src={uc7} alt=""></img>
                  </div>
                </div>
              </div>
              <p style={paragraphStylePhotos}>
                nyc
              </p>
              <div class="scroll-container">
                <div class="image-grid">
                  <div class="grid-item">
                    <img src={nyc5} alt=""></img>
                  </div>
                  <div class="grid-item">
                    <img src={nyc2} alt=""></img>
                  </div>
                  <div class="grid-item">
                    <img src={nyc6} alt=""></img>
                  </div>
                  <div class="grid-item">
                    <img src={nyc4} alt=""></img>
                  </div>
                  <div class="grid-item">
                    <img src={nyc3} alt=""></img>
                  </div>
                  <div class="grid-item">
                    <img src={nyc7} alt=""></img>
                  </div>
                  <div class="grid-item">
                    <img src={nyc8} alt=""></img>
                  </div>
                </div>
              </div>
              <p style={paragraphStylePhotos}>
                la mer
              </p>
              <div class="scroll-container">
                <div class="image-grid">
                  <div class="grid-item">
                    <img src={ss1} alt=""></img>
                  </div>
                  <div class="grid-item">
                    <img src={ss3} alt=""></img>
                  </div>
                  <div class="grid-item">
                    <img src={ss4} alt=""></img>
                  </div>
                  <div class="grid-item">
                    <img src={ss5} alt=""></img>
                  </div>
                  <div class="grid-item">
                    <img src={ss6} alt=""></img>
                  </div>
                  <div class="grid-item">
                    <img src={ss7} alt=""></img>
                  </div>
                  <div class="grid-item">
                    <img src={ss8} alt=""></img>
                  </div>
                </div>
              </div>
              <p style={paragraphStylePhotos}>
                los angeles
              </p>
              <div class="scroll-container">
                <div class="image-grid">
                  <div class="grid-item">
                    <img src={la2} alt=""></img>
                  </div>
                  <div class="grid-item">
                    <img src={la8} alt=""></img>
                  </div>
                  <div class="grid-item">
                    <img src={la5} alt=""></img>
                  </div>
                  <div class="grid-item">
                    <img src={la9} alt=""></img>
                  </div>
                  <div class="grid-item">
                    <img src={la10} alt=""></img>
                  </div>
                  <div class="grid-item">
                    <img src={la7} alt=""></img>
                  </div>
                  <div class="grid-item">
                    <img src={la11} alt=""></img>
                  </div>
                </div>
              </div>
              <p style={paragraphStylePhotos}>
                videography 
              </p>
              <div class="scroll-container">
                <div class="image-grid">
                  <div class="grid-item">
                    <iframe 
                      title="video1"
                      class="video-frame" 
                      src="https://www.youtube.com/embed/vN6wxrKgPXs?si=TkoNZAcf1TZGJGfX" 
                      frameborder="0" 
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                      allowfullscreen>
                    </iframe>
                  </div>
                  <div class="grid-item">
                    <iframe 
                      title="video2"
                      class="video-frame" 
                      src="https://youtube.com/embed/AgaxTAufaA4?si=uaY92Z6c7XyniPOn" 
                      frameborder="0" 
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                      allowfullscreen>
                    </iframe>
                  </div>
                </div>
              </div>    
              <p style={paragraphStyle}></p>
            </section>
            <section id="section4" style={{ textAlign: "right" }}>
              <h2 style={blueColor}>contact</h2>
              <p style={paragraphStyle}>
                <ul style={wideListStyle}>
                  <li style={listItemStyle}>
                    <a
                      href="mailto:st.stoikos@gmail.com"
                      style={linkStyle}
                      onMouseEnter={(e) => (e.target.style.color = "yellow")} // Changes color on hover
                      onMouseLeave={(e) =>
                        (e.target.style.color = "whitesmoke")
                      } // Restores color when not hovered
                      onClick={handleEmailLinkClick}
                    >
                      email 📧
                    </a>
                  </li>

                  <li style={listItemStyle}>
                    <a
                      href="https://www.linkedin.com/in/stefanos-stoikos"
                      style={linkStyle}
                      onMouseEnter={(e) => (e.target.style.color = "yellow")} // Changes color on hover
                      onMouseLeave={(e) =>
                        (e.target.style.color = "whitesmoke")
                      } // Restores color when not hovered
                      onClick={() =>
                        handleExternalLinkClick(
                          "https://www.linkedin.com/in/stefanos-stoikos"
                        )
                      } // Opens link in new tab on click
                      target="_blank" // Opens the link in a new tab
                      rel="noopener noreferrer" // Recommended for security reasons
                    >
                      linkedin ↯
                    </a>
                  </li>
                  <li style={listItemStyle}>
                    <a
                      href="https://scholar.google.com/citations?user=FLR5YEgAAAAJ&hl=en"
                      style={linkStyle}
                      onMouseEnter={(e) => (e.target.style.color = "yellow")} // Changes color on hover
                      onMouseLeave={(e) =>
                        (e.target.style.color = "whitesmoke")
                      } // Restores color when not hovered
                      onClick={() =>
                        handleExternalLinkClick(
                          "https://scholar.google.com/citations?user=FLR5YEgAAAAJ&hl=en"
                        )
                      } // Opens link in new tab on click
                      target="_blank" // Opens the link in a new tab
                      rel="noopener noreferrer" // Recommended for security reasons
                    >
                      google scholar ↯
                    </a>
                  </li>
                  <li style={listItemStyle}>
                    <a
                      href="https://github.com/Stefanos-stk"
                      style={linkStyle}
                      onMouseEnter={(e) => (e.target.style.color = "yellow")} // Changes color on hover
                      onMouseLeave={(e) =>
                        (e.target.style.color = "whitesmoke")
                      } // Restores color when not hovered
                      onClick={() =>
                        handleExternalLinkClick(
                          "https://github.com/Stefanos-stk"
                        )
                      } // Opens link in new tab on click
                      target="_blank" // Opens the link in a new tab
                      rel="noopener noreferrer" // Recommended for security reasons
                    >
                      github ↯
                    </a>
                  </li>
                </ul>
              </p>
            </section>
          </div>
        </div>
      </div>
      <P5Component key={key} />
    </main>
  );
}

export default App;
